import { sessionStorage } from 'react-storage';
import { EventValues } from '@sial/common-utils';
import { searchResultsRoute } from '@src/routes';
import { ProductSearchType, determineQueryFocus, getValidSearch, formatCasNumberSearchTerm } from '@utils/searchUtils';
export var buildProductAliasPayload = function buildProductAliasPayload(_ref) {
  var _term;

  var action = _ref.action,
      detail = _ref.detail,
      focus = _ref.focus,
      productId = _ref.productId,
      productBrand = _ref.productBrand;
  var type;
  var term;

  switch (action) {
    case 'ec number':
      type = ProductSearchType.EgecNumber;
      break;

    case 'mdl number':
      type = ProductSearchType.MdlNumber;
      break;

    case 'cas number':
      type = ProductSearchType.CasNumber;
      term = formatCasNumberSearchTerm(detail);
      break;

    default:
  }

  return {
    action: action + ' click',
    detail: detail,
    component: 'body',
    elementType: 'link',
    elementText: detail,
    linkUrl: "".concat(searchResultsRoute.searchResults(term || detail || undefined)).concat(getValidSearch({
      term: ((_term = term) === null || _term === void 0 ? void 0 : _term.toLowerCase()) || (detail === null || detail === void 0 ? void 0 : detail.toLowerCase()),
      focus: determineQueryFocus(focus),
      type: type
    })),
    productId: productId,
    productBrand: productBrand
  };
};
/*
  BUILDS PROMO OBJECTS FOR CAROUSELS

  Could be used elsewhere for product data structures that use the gaProductCode alongside product data.
  Carousels is the original primary purpose.

  Can build both GA4 promo objects and legacy UA promo objects.
*/

export var buildGaPromoObjects = function buildGaPromoObjects(rawGaProductCode, pickedProduct) {
  var _rawGaProductCode$toL, _pickedProduct$produc, _pickedProduct$produc2, _pickedProduct$brand, _pickedProduct$brand$;

  var position = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
  var listNameOverride = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  var gaProductCode = (rawGaProductCode === null || rawGaProductCode === void 0 ? void 0 : (_rawGaProductCode$toL = rawGaProductCode.toLowerCase()) === null || _rawGaProductCode$toL === void 0 ? void 0 : _rawGaProductCode$toL.split('|')) || [];
  var pageParent = sessionStorage.getItem('DYNAMIC_CAROUSEL_PRODUCTS_PAGE_PARENT');
  var legacyPromo = {
    id: (gaProductCode === null || gaProductCode === void 0 ? void 0 : gaProductCode[0]) || EventValues.NotAvailable,
    name: (gaProductCode === null || gaProductCode === void 0 ? void 0 : gaProductCode[1]) || EventValues.NotAvailable,
    creative: (gaProductCode === null || gaProductCode === void 0 ? void 0 : gaProductCode[2]) || EventValues.NotAvailable
  };
  var ga4PromoObj = {
    creative_name: gaProductCode[2] || EventValues.NotAvailable,
    promotion_id: gaProductCode[0] || EventValues.NotAvailable,
    promotion_name: gaProductCode[1] || EventValues.NotAvailable,
    index: position,
    item_id: (pickedProduct === null || pickedProduct === void 0 ? void 0 : (_pickedProduct$produc = pickedProduct.productKey) === null || _pickedProduct$produc === void 0 ? void 0 : _pickedProduct$produc.toLowerCase()) || (pickedProduct === null || pickedProduct === void 0 ? void 0 : (_pickedProduct$produc2 = pickedProduct.productNumber) === null || _pickedProduct$produc2 === void 0 ? void 0 : _pickedProduct$produc2.toLowerCase()) || EventValues.NotAvailable,
    item_brand: (pickedProduct === null || pickedProduct === void 0 ? void 0 : (_pickedProduct$brand = pickedProduct.brand) === null || _pickedProduct$brand === void 0 ? void 0 : (_pickedProduct$brand$ = _pickedProduct$brand.key) === null || _pickedProduct$brand$ === void 0 ? void 0 : _pickedProduct$brand$.toLowerCase()) || EventValues.NotAvailable,
    item_list_id: listNameOverride || pageParent.detail,
    item_list_name: listNameOverride || pageParent.detail
  };
  return {
    promo: legacyPromo,
    ga4PromoObj: ga4PromoObj
  };
};
export var calculateDiscountPercentage = function calculateDiscountPercentage(listPrice, discountedPrice) {
  return (listPrice !== null && listPrice !== void 0 ? listPrice : 0) > (discountedPrice !== null && discountedPrice !== void 0 ? discountedPrice : 0) ? Math.round(((listPrice !== null && listPrice !== void 0 ? listPrice : 0) - (discountedPrice !== null && discountedPrice !== void 0 ? discountedPrice : 0)) / (listPrice !== null && listPrice !== void 0 ? listPrice : 1) * 100) : undefined;
};